<template>
  <div>
    <q-card
      v-if="maintenance"
      class="absolute-center q-pa-xl bg-blue-10 text-blue-1 rounded-borders"
      flat
    >
      <q-card-section class="text-center">
        <div>
          <q-icon name="las la-wrench" size="48px" />
          <q-icon name="las la-server" size="64px" />
        </div>
        <div class="text-h5 q-pt-md">
          Sorry we are currently undergoing maintenance.
        </div>
        <div class="text-subtitle1">Please check back later.</div>
      </q-card-section>
    </q-card>
    <router-view v-else :key="$i18n.locale" />
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

import { useQuasar } from "quasar";

import { useMainStore } from "stores/main";

import { firestore } from "boot/firebase";
import { doc, getDoc, onSnapshot } from "firebase/firestore";

export default defineComponent({
  name: "App",
  setup() {
    const { dialog, loading, notify } = useQuasar();

    const maintenance = ref(false);
    const version = ref("");

    const getMaintenance = async () => {
      try {
        loading.show();
        const docRef = doc(firestore, "Global", "maintenance");
        const docSnap = await getDoc(docRef);
        maintenance.value = docSnap.data().enabled;
      } catch (error) {
        notify({
          message: "Error getting maintenance status",
          type: "negative",
        });
      } finally {
        loading.hide();
      }
    };

    const getVersion = async () => {
      try {
        loading.show();
        const docRef = doc(firestore, "Global", "version");
        const docSnap = await getDoc(docRef);

        onSnapshot(docRef, (doc) => {
          version.value = doc.data().currentVersion;
          if (process.env.version != version.value) {
            dialog({
              title: "New Version Available",
              message:
                "A new version of the app is available. Please refresh the page to update.",
              ok: "Refresh",
              cancel: "Dimiss",
            }).onOk(() => {
              window.location.reload();
            });
          }
        });
      } catch (error) {
        notify({
          message: "Error getting version",
          type: "negative",
        });
      } finally {
        loading.hide();
      }
    };

    getMaintenance();
    getVersion();

    const { watchForAuthStateChange, loadLocale } = useMainStore();
    watchForAuthStateChange();
    loadLocale();

    return { maintenance };
  },
});
</script>
