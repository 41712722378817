import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage, connectStorageEmulator } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDG3qtgS7isUWYguT7tBS6-bXStxptkrKg",
  authDomain: "litelead-a6774.firebaseapp.com",
  projectId: "litelead-a6774",
  storageBucket: "litelead-a6774.appspot.com",
  messagingSenderId: "1066245623199",
  appId: "1:1066245623199:web:369abad64f81b1cad681aa",
  measurementId: "G-QBQJG7L7W0",
};

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
const auth = getAuth(app);
const functions = getFunctions(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

if (process.env.DEV) {
  connectAuthEmulator(auth, "http://127.0.0.1:49999");
  connectFirestoreEmulator(firestore, "127.0.0.1", 49997);
  connectFunctionsEmulator(functions, "127.0.0.1", 49998);
  connectStorageEmulator(storage, "127.0.0.1", 49995);
}

export { analytics, auth, firestore, functions, storage };
