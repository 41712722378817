import { useQuasar } from "quasar";

import { useI18n } from "vue-i18n";

import { auth, firestore } from "boot/firebase";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";

import { ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

import { defineStore } from "pinia";

import { useRouteParams } from "@vueuse/router";

import { Notify } from "quasar";

import de from "quasar/lang/de";
import en from "quasar/lang/en-US";
import it from "quasar/lang/it";
import es from "quasar/lang/es";

export const useMainStore = defineStore("main", () => {
  const i18n = useI18n();

  const quasar = useQuasar();
  const router = useRouter();
  const route = useRoute();

  const user = ref(null);
  const userInternal = ref(null);
  const account = ref(null);

  const userListener = ref(null);
  const userInternalListener = ref(null);
  const accountListener = ref(null);

  const userInfoBuffer = ref(null);

  const accountId = useRouteParams("accountId");

  const quasarLanguagePacks = {
    de,
    en,
    it,
    es,
  };

  const { locale } = i18n;

  const setLocale = (_locale) => {
    locale.value = _locale;
    quasar.lang.set(
      quasarLanguagePacks[_locale.split("-").slice(0, 1).join("")]
    );
    localStorage.setItem("locale", _locale);
  };

  const loadLocale = async () => {
    const _locale = localStorage.getItem("locale");
    setLocale(_locale ?? "en-US");
  };

  const login = async (_user) => {
    try {
      await signInWithEmailAndPassword(auth, _user.email, _user.password);
    } catch (error) {
      throw error;
    }
  };

  const register = async (_user) => {
    try {
      const userCredentials = await createUserWithEmailAndPassword(
        auth,
        _user.email,
        _user.password
      );

      await updateProfile(userCredentials.user, {
        displayName: _user.name,
      });
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      throw error;
    }
  };

  const getInternalUser = async (accountId, userId) => {
    if (userInternalListener.value) userInternalListener.value();
    try {
      const docRef = doc(firestore, "Accounts", accountId, "Users", userId);
      userInternalListener.value = onSnapshot(docRef, (doc) => {
        userInternal.value = { ...doc.data(), id: doc.id, path: docRef.path };
      });
    } catch (error) {
      throw error;
    }
  };

  const getUser = async (userId) => {
    if (userListener.value) userListener.value();
    try {
      const docRef = doc(firestore, "Users", userId);
      userListener.value = onSnapshot(docRef, (doc) => {
        user.value = { ...doc.data(), path: docRef.path };
        if (user.value.banned) {
          Notify({
            type: "negative",
            message: "You have been banned from using this app",
          });
          logout();
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const getAccount = async (accountId) => {
    if (user.value === null) return;
    if (accountListener.value) accountListener.value();
    try {
      const docRef = doc(firestore, "Accounts", accountId);
      accountListener.value = onSnapshot(
        docRef,
        (doc) => {
          account.value = { ...doc.data(), id: doc.id, path: docRef.path };
        },
        (error) => {
          console.log("ERROR >> ", error);
          router.push("/");
        }
      );
      await getInternalUser(accountId, user.value.uid);
    } catch (error) {
      console.log("error on getaccount >>", error.message);
      router.push("/");
    }
  };

  const clearUser = () => {
    user.value = null;
    account.value = null;
    if (userListener.value) {
      userListener.value();
    }
    if (userInternalListener.value) {
      userInternalListener.value();
    }
    if (accountListener.value) {
      accountListener.value();
    }
  };

  const clearAccount = () => {
    account.value = null;
    if (accountListener.value) {
      accountListener.value();
    }
  };

  const watchForAuthStateChange = () => {
    onAuthStateChanged(auth, async (_user) => {
      if (_user) {
        getUser(_user.uid);
      } else {
        clearUser();
        router.push("/auth/login");
      }
    });
  };

  const requiresAccess = (permissions) => {
    for (let permission of permissions) {
      if (!userInternal.value.permissions.includes(permission)) {
        return false;
      }
    }
    return true;
  };

  watch(accountId, (accountIdNew, accountIdOld) => {
    if (accountIdNew && accountIdNew != accountIdOld) {
      getAccount(accountIdNew);
    }
  });

  watch(user, (userNew, userOld) => {
    console.log(accountId.value);
    if (userNew && !account.value && accountId.value) {
      getAccount(accountId.value);
    } else if (userNew && !accountId.value) {
      router.push("/");
    }
  });

  return {
    login,
    register,
    logout,
    watchForAuthStateChange,
    account,
    user,
    getAccount,
    clearAccount,
    setLocale,
    loadLocale,
    requiresAccess,
    userInternal,
  };
});
